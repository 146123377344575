import React from 'react'
// import { Layout } from '../components/Layout'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'
import Container from 'react-bootstrap/Container'

const PageNotFound = () => (
  <Layout>
  	<Container>
    <h1>Oops! Nothing here.</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default PageNotFound
